<template>
  <div class="px-8 px-md-12 py-8 ranking-list-detail">
    <!-- Header -->
    <v-row
      color="white"
      class="d-flex"
    >
      <v-col>
        <v-row>
          <v-col>
            <v-row>
              <router-link :to="{ name: 'competition-ranking-filter'}">
                <v-icon
                  color="primary"
                  size="16"
                >
                  arrow_back
                </v-icon>
                {{ `${$t('activity.editActivity.navigateBack')} ${$t('competition.sections.ranking')}` }}
              </router-link>
            </v-row>
            <v-row class="my-12">
              <h2 v-text="$t('common.inscription')" />
            </v-row>
            <v-row
              justify="space-between"
              class="my-6"
            >
              <div><v-icon color="primary" size="24" class="mr-3"> view_quilt</v-icon><span v-text="currentCohort.name" /></div>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-select
            v-model="selectedGroup"
            :items="groupItems"
            item-text="name"
            item-value="id"
            :label="$t('project.sections.groups')"
            append-icon="mdi-chevron-down"
            style="max-width: 33%;"
          />
        </v-row>
        <v-row
          v-if="isDataLoaded && groupedTeams.length"
          class="mt-12"
        >
          <div v-for="(group, i) in groupedTeams" :key="i" class="w-100 mb-4">
            <div class="title-group-table w-100 d-flex align-center px-3 mx-0">
              <h4>{{ `${ $t('common.group')} ${group.name}` }}</h4>
            </div>
            <v-data-table
              v-if="group.teamList"
              :headers="headers" :hide-default-footer="true" :items="group.teamList" :items-per-page="-1" dense
            >
              <template #[`header.statsPoints`]="{ header }">
                <span class="font-weight-bold mr-4" v-text="header.text" />
                <v-tooltip top color="white">
                  <template #activator="{ on, attrs }">
                    <v-icon color="primary" small class="ml-1" v-bind="attrs" v-on="on" v-text="'info_outline'" />
                  </template>
                  <div class="tooltip-text pa-2" v-html="header.tooltipText" />
                </v-tooltip>
              </template>
              <template #[`item.name`]="{ item }">
                <div class="d-flex align-center">
                  <span style="width: 84px" class="text-caption d-md-inline-block mr-2 mr-md-0 ml-md-7" v-text="item.rank" />
                  <avatar-field :value="item.logo" :active="false" :is-square="true" custom-size="40" custom-icon="none" />
                  <div class="match-group-list__name-team ml-3">
                    <v-tooltip top color="white">
                      <template #activator="{ on, attrs }">
                        <span class="ml-2 d-block text-truncate text-caption ranking-list-detail__name-team" v-bind="attrs" v-on="on" v-text="item.name" />
                      </template>
                      <div class="tooltip-text pa-2" v-text="item.name" />
                    </v-tooltip>
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RankingList',
  components: {
    AvatarField: () => import('@/components/AvatarField'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, required: true },
  },
  data() {
    return {
      tab: 0,
      selectedGroup: null,
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({ organization: 'organization/data' }),
    ...mapGetters({ groups: 'competition/groups' }),
    ...mapGetters({ rawTeams: 'competition/teams' }),
    ...mapGetters({ cohorts: 'competition/cohorts' }),
    ...mapGetters({ clubs: 'competition/clubs' }),
    isSoccer: ({ organization }) => organization.sport === 'soccer',
    isBasketball: ({ organization }) => organization.sport === 'basketball',
    teams: ({ rawTeams, cohortId }) => rawTeams.filter(t => t.cohortId === cohortId),
    selectedStageId({ tab }) {
      return tab === 0 ? 'rrobin' : 'elimination'
    },
    currentCohort({ cohorts, cohortId }) {
      return cohorts.find(cohort => cohort.id === cohortId)
    },
    selectedGroups({ groups, cohortId, selectedStageId, selectedGroup }) {
      return groups
        .filter(group => group.stageId === selectedStageId && group.cohortId === cohortId)
        .filter(group => selectedGroup ? group.id === selectedGroup : true)
    },
    groupItems({ groups, selectedStageId }) {
      return [{ name: this.$t('filters.all'), id: null, stageId: selectedStageId }, ...groups]
        .filter(group => group.stageId === selectedStageId)
    },
    headers({ isSoccer, tooltipText }) {
      return [
        { text: this.$t('common.team'), value: 'name', sortable: false, width: '30%' },
        { text: this.$t('project.competition.headers.gp'), value: 'statsMatches', sortable: false },
        { text: this.$t('project.competition.headers.w'), value: 'statsWins', sortable: false },
        ...(isSoccer ? [{ id: 4, text: this.$t('project.competition.headers.d'), value: 'statsDraws', sortable: false }] : []),
        { text: this.$t('project.competition.headers.l'), value: 'statsLoses', sortable: false },
        {
          text: isSoccer ? this.$t('project.competition.headers.g') : this.$t('project.competition.headers.p'),
          value: 'statsGoals',
          sortable: false,
        },
        { text: this.$t('project.competition.headers.pm'), value: 'statsGoalDifference', sortable: false },
        { text: this.$t('project.competition.headers.pts'), tooltipText, value: 'statsPoints', sortable: false },
      ]
    },
    tooltipText({ isSoccer, isBasketball }) {
      return [
        ['gp', 'gamesPlayed'],
        ['w', 'wins'],
        ...(isSoccer ? [['d', 'draws']] : []),
        ['l', 'losses'],
        ...(isSoccer ? [['g', 'goals']] : []),
        ...(isBasketball ? [['p', 'win%']] : []),
        ['pm', isSoccer ? 'goalAverage' : 'plusMinus'],
        ['pts', 'points'],
      ].map(literals => `<p>${this.$t(`project.competition.headers.${literals[0]}`)}: ${this.$t(`project.competition.headers.${literals[1]}`)}</p>`)
        .reduce((acc, curr) => `${acc}<p>${curr}</p>`)
    },
    groupedTeams({ teams, selectedGroups, selectedStageId, isSoccer, headers }) {
      return selectedGroups.length ? selectedGroups.map(
        group => ({
          ...group,
          teamList: teams
            .filter(team => team.dataByStageId?.[selectedStageId]?.groupId === group.id)
            .sort((a, b) => a.dataByStageId.rrobin.position !== b.dataByStageId.rrobin.position
              ? (a.dataByStageId.rrobin.position ?? 99) - (b.dataByStageId.rrobin.position ?? 99)
              : a.dataByStageId.rrobin.index - b.dataByStageId.rrobin.index)
            .map((team, index) => {
              const stats = team?.dataByStageId[this.selectedStageId].stats
              return {
                ...team,
                rank: index + 1,
                name: team.name,
                logo: team.logo,
                statsMatches: stats ? stats.matches : '-',
                statsWins: stats ? stats.wins : '-',
                ...(this.isSoccer && { statsDraws: stats ? stats.draws : '-' }),
                statsLoses: stats ? stats.loses : '-',
                ...(this.isSoccer ? { statsGoals: stats ? stats.goals : '-' } : { statsGoals: stats?.winPercentage ?? '-' }),
                statsGoalDifference: stats ? stats.goalDifference : '-',
                statsPoints: stats ? stats.points : '-',
              }
            }),
        }),
      ) : []
    },
  },
  async created() {
    const { organizationId, projectId, cohortId } = this
    this.$store.commit('loader/show')
    await this.$store.dispatch('competition/bindGroups', { organizationId, projectId, cohortId })
    await this.$store.dispatch('competition/bindTeams', { organizationId, projectId, cohortId })
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr {
  height: 5rem;
}

.ranking-list-detail__name-team {
  max-width: 6rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 22rem;
  }
}
</style>
